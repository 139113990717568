import { useEffect, useState } from "react";

import Layout from "../../components/reusable/Layout";
import Title from "../../components/reusable/Title";
import ScaleBalance from "./ScaleBalance";
import CalsTable from "./CalsTable";
import FuelSources from "./FuelSources";
import classes from "../TrainingProgramTrianglePage/TrainingProgramTrianglePage.module.css";

const CaloricBalancePage = ({
  data,
  onSvgConverter,
  isRestoreReport,
}: {
  data: any;
  onSvgConverter: (content: string, result: string, imgWidth: number) => void;
  isRestoreReport: boolean;
}) => {
  const [imgLoad, setImgLoad] = useState(false);

  useEffect(() => {
    onSvgConverter("scaleBalance", "resultScale", 755);
    setImgLoad(true);
  }, [onSvgConverter]);

  let imgScaleBalance = (
    <div id="scaleBalance">
      <ScaleBalance data={data.SECTION1} isRestoreReport={isRestoreReport} />
    </div>
  );

  return (
    <Layout>
      <Title title={data.TITLE} />
      <div id="resultScale" className={classes.imgScale}>
        {!imgLoad && imgScaleBalance}
      </div>
      <CalsTable data={data.SECTION2} />
      <FuelSources data={data.SECTION3} isRestoreReport={isRestoreReport} />
    </Layout>
  );
};

export default CaloricBalancePage;
