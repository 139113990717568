import classes from "./RiskChartBar.module.css";

import riskPoint from "../../images/riskPoint.png";

type improveArea = { INDEX: number; TEXT: string; TITLE: string };

type Metric = {
  HOW_IT_IS_MEASURED: string;
  HOW_IT_IS_MEASURED_TEXT: string;
  INDEX: number;
  SUGGESTIONS: { IMPROVE_AREAS: improveArea[]; INDEX: number; TITLE: string }[];
  TITLE1: string;
  TITLE2: string;
  SUBTITLE?: string;
  TOP: string;
  VALUE: number;
  WHAT_IT_IS: string;
  WHAT_IT_IS_TEXT: string;
  WHY_ITS_IMPORTANT: string;
  WHY_ITS_IMPORTANT_TEXT: string;
  LOW?: string;
  HIGH?: string;
};

interface Props {
  data: Metric;
  onSvgConverter: (
    content: string,
    result: string,
    imgWidth: number
  ) => JSX.Element;
  useLargerFont: boolean;
  customGap?: boolean;
}

const RiskChartBar = ({
  data,
  useLargerFont,
  customGap,
}: Props): JSX.Element => {
  let fillColorNoRisk: string = `${classes.fillInnerRisk} `;
  let fillColorRisk: string = `${classes.fillInnerRisk} `;
  let label1Style: string = `${classes.riskLabel}`;
  let label2Style: string = `${classes.riskLabel}`;
  let balancePointStyle;

  if (data.VALUE === 100) {
    fillColorRisk = `${classes.fillInnerRisk} ${classes.noActive} `;
    label2Style = `${classes.riskLabel} ${classes.noActive}`;
    balancePointStyle = { left: "75%" };
  } else {
    fillColorNoRisk = `${classes.fillInnerRisk} ${classes.noActive} `;
    label1Style = `${classes.riskLabel} ${classes.noActive}`;
    balancePointStyle = { left: "25%" };
  }

  return (
    <div>
      <div
        className={classes.header}
        style={{ fontSize: useLargerFont ? "16px" : "12px" }}
      >
        <span className={classes.bold}>{data.TITLE1}</span>
      </div>
      <div
        className={classes.container}
        style={customGap ? { marginTop: "5px" } : {}}
      >
        <div className={classes.inner}>
          <div
            className={fillColorNoRisk}
            style={{
              left: "50%",
              background: `linear-gradient(
      270deg,
      rgb(18, 63, 227) 0%,
      rgba(115, 76, 188, 0.6) 77.06%,
      rgba(207, 86, 152, 0) 100%
    )`,
            }}
          ></div>
          <div
            className={fillColorRisk}
            style={{
              left: "0",
              background: `linear-gradient(
    90deg,
    rgb(240, 36, 104) 6.13%,
    rgba(225, 143, 20, 0.5) 93%,
    rgba(207, 86, 152, 0) 100%
  )`,
            }}
          ></div>
          <img
            className={classes.balancePoint}
            src={riskPoint}
            style={balancePointStyle}
            alt="50% point"
          />
        </div>
      </div>

      <div
        className={classes.labels}
        style={{
          marginTop: useLargerFont ? "0px" : "0",
          fontSize: useLargerFont ? "10px" : "10px",
        }}
      >
        <div className={label2Style}>{data.LOW}</div>
        <div className={label1Style}>{data.HIGH}</div>
      </div>
    </div>
  );
};

export default RiskChartBar;
