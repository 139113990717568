import { useEffect, useState } from "react";

import Layout from "../../components/reusable/Layout";
import Title from "../../components/reusable/Title";
import CustomCard from "../../components/reusable/CustomCard";
import BiologicalAge from "./BiologicalAge/BiologicalAge";
import LineChartSection from "./BiaxialLineChart/LineChartSection";

import classes from "./BiologicalAgePage.module.css";
import ServiceSection from "../UserServicesPage/ServiceSection";

type LineData = {
  x: number;
  line1?: number;
  line2?: number;
  line3?: number;
  line4?: number;
  line5?: number;
  line6?: number;
  lineUser?: number;
};

type Legend2Obj = { INDEX: number; VALUE: string };

interface Props {
  data: {
    DESCRIPTION: string | null;
    INDEX: number;
    SECTION1: {
      BIOLOGICAL_AGE: string;
      BIOLOGICAL_AGE_VALUE: number;
      CHRONOLOGICAL_AGE: string;
      CHRONOLOGICAL_AGE_VALUE: number;
      COMPARED_TO: string;
      DESCRIPTION: string;
      FAT_BURNING_EFFICIENCY_VALUE: number;
      FAT_BURN_EFFICIENCY: string;
      INCREASES_YOUR_AGE: string;
      INDEX: number;
      METABOLIC_RATE: string;
      METABOLIC_RATE_VALUE: number;
      REDUCES_YOUR_AGE: string;
      TITLE: string;
      VO2MAX: string;
      VO2MAX_VALUE: number;
    };
    SECTION2: {
      DESCRIPTION: string;
      INDEX: number;
      LEGEND_1: string;
      LEGEND_2: Legend2Obj[];
      LEGEND_X_1: string;
      LEGEND_Y_1: string;
      LEGEND_Y_2: string;
      TITLE: string;
    };
    SERVICES: any[];
    TITLE: string;
  };
  onSvgConverter: (
    content: string,
    result: string,
    imgWidth: number
  ) => JSX.Element;
  biaxialLineChartData: {
    gender: string;
    lines_men: LineData[];
    lines_women: LineData[];
  };
  dataNew: any;
  showVO2MaxChart: boolean;
  isRestoreReport?: boolean;
}

const BiologicalAgePage = ({
  data,
  onSvgConverter,
  biaxialLineChartData,
  dataNew,
  showVO2MaxChart,
  isRestoreReport,
}: Props): JSX.Element => {
  const [imgBiologicalLoad, setImgBiologicalLoad] = useState(false);
  const [imgLineChartLoad, setImgLineChartLoad] = useState(false);
  let normalizedServiceData: any[] = [];
  useEffect(() => {
    onSvgConverter("biologicalAge", "imgBiological", 726);
    setImgBiologicalLoad(true);
    onSvgConverter("lineChart", "imgLineChart", 755);
    setImgLineChartLoad(true);
  }, [onSvgConverter]);

  if (isRestoreReport) {
    normalizedServiceData = data.SERVICES.map((service: any) => {
      return {
        TITLE: service.TITLE,
        INDEX: service.INDEX,
        IMAGESMALL_URL: service.IMAGE_URL,
        ACTIVE_INGREDIENT_LABEL: "Active Ingredient",
        ACTIVE_INGREDIENT: service.TITLE,
        PNOE_BENEFITS_LABEL: "Metrics benefited",
        ADDITIONAL_BENEFITS_LABEL: "Additional benefits",
        PNOE_BENEFITS: service.TABS[0].SECTION_1.map(
          (item: any, index: number) => {
            return { INDEX: item.INDEX, VALUE: item.TITLE };
          }
        ),
        ADDITIONAL_BENEFITS: service.TABS[0].SECTION_2.map(
          (item: any, index: number) => {
            return { INDEX: item.INDEX, VALUE: item.TITLE };
          }
        ),
      };
    });
  }

  const imgBiologicalAge = (
    <div id="biologicalAge" className={classes.imgBiologicalAge}>
      <CustomCard cardWidth="760px">
        <BiologicalAge data={data.SECTION1} />
      </CustomCard>
    </div>
  );

  const imgLineChart = (
    <LineChartSection
      id="lineChart"
      data={data.SECTION2}
      biaxialLineChartData={biaxialLineChartData}
      dataNew={dataNew}
    />
  );

  return (
    <Layout>
      <Title title={data.TITLE} />
      <div className={classes.section1}>
        <div id="imgBiological" className={classes.imgBiologicalAge}>
          {!imgBiologicalLoad && imgBiologicalAge}
        </div>
      </div>
      <div className={classes.description}>{data.SECTION1.DESCRIPTION}</div>
      {showVO2MaxChart && (
        <div className={classes.section2}>
          <div id="imgLineChart" className={classes.imgLineChart}>
            {!imgLineChartLoad && imgLineChart}
          </div>
          <div className={classes.description}>{data.SECTION2.DESCRIPTION}</div>
        </div>
      )}
      {isRestoreReport && (
        <div>
          <div
            className={classes.description}
            style={{ marginTop: "80px", textAlign: "center", fontSize: "14px" }}
          >
            Increasing NAD+ levels may help slow the aging process and reduce
            the risk of age-related diseases.
          </div>
          <ServiceSection
            data={normalizedServiceData[0]}
            type="restoreCustomReport"
          />
        </div>
      )}
    </Layout>
  );
};

export default BiologicalAgePage;
